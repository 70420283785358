import ReactDOM from 'react-dom/client';
import { CLRouterProvider } from 'anthill-component-library/helpers/master'
import { AppContextProvider } from 'anthill-component-library/contexts/app'
import { ClinetContextProvider } from './Contexts/ClientContext';
import routes from './components/Routes';
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AppContextProvider>
    <ClinetContextProvider>
      <CLRouterProvider router={routes} />
    </ClinetContextProvider>
  </AppContextProvider>
);
