import { AuthContext } from "anthill-component-library/dist/AuthContext-e2762bed";
import { createContext, useContext, useEffect, useState } from "react";

const ClinetContext = createContext({})

function ClinetContextProvider({ children }) {

    const { employee } = useContext(AuthContext)
    const [selectedClient, setSelectedClient] = useState(null);

    useEffect(() => {
        const savedClient = localStorage.getItem("selectedClient");

        if (savedClient) {
            setSelectedClient(savedClient);
        } else {
            const employeeData = JSON.parse(localStorage.getItem("employee"));
            if (employeeData && employeeData?.clients?.length > 0) {
                const initialClient = employeeData.clients[0].client?._id;
                setSelectedClient(initialClient);
                localStorage.setItem("selectedClient", initialClient);
            }
        }
    }, [employee]);


    useEffect(() => {
        if (!employee) {
            setSelectedClient(null);
            localStorage.removeItem("selectedClient");
        }
    }, [employee]);

    return (
        <ClinetContext.Provider
            value={{
                selectedClient,
                setSelectedClient
            }}
        >
            {children}
        </ClinetContext.Provider>
    )
}

export {
    ClinetContextProvider,
    ClinetContext
}
