import LazyLoad from "../../Partials/LazyLoad/LazyLoad"

const Dashboard = LazyLoad(() => import("./Dashboard/Dashboard"))
const TechProducts = LazyLoad(() => import("./TechProduct/TechProducts"))
const SingleTechProductDashboard = LazyLoad(() => import("./TechProduct/SingleTechProduct/SingleTechProductDashboard"))
const TechProductApp = LazyLoad(() => import("./TechProduct/SingleTechProduct/TechProductApp/TechProductApp"))
const TechProductEpics = LazyLoad(() => import("./TechProduct/SingleTechProduct/TechProductEpics/TechProductEpics"))
const TechProductStories = LazyLoad(() => import("./TechProduct/SingleTechProduct/TechProductStories/TechProductStories"))
const TechProductUsers = LazyLoad(() => import("./TechProduct/SingleTechProduct/TechProductUsers/TechProductUsers"))


const MainRoutes = [
    {
        path: 'dashboard',
        element: <Dashboard />
    },
    {
        path: 'techProducts',
        element: <TechProducts />
    },
    {
        path: 'singleTechProductScreens/:techProductId',
        element: <SingleTechProductDashboard />
    },
    {
        path: 'techProductApps/:techProductId',
        element: <TechProductApp />
    },
    {
        path: 'techProductUsers/:techProductId',
        element: <TechProductUsers />
    },
    {
        path: 'techProductEpics/:techProductId',
        element: <TechProductEpics />
    },
    {
        path: 'techProductStories/:techProductId/:epicId',
        element: <TechProductStories />
    },
]

export default MainRoutes

