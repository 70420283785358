import { CLUseNavigate, CLUseParams } from 'anthill-component-library/dist/helpers';
import { AuthContext } from 'anthill-component-library/contexts/app';
import { useEffect, useContext, useState } from 'react';
import axios from 'axios';

function VerifyClientContact() {
    const { token } = CLUseParams();
    const { setEmployee } = useContext(AuthContext);
    const [isEmailVerified, setIsEmailVerified] = useState(null)
    const navigate = CLUseNavigate()

    useEffect(() => {
        const verifyEmail = async () => {
            if (token) {
                try {
                    const { data } = await axios.patch(
                        `${process.env.REACT_APP_BACKEND_URL}api/clientApp/public/clientAuth/verifyClientContact/${token}`
                    );
                    if (data?.isEmailVerified) {
                        localStorage.setItem("employee", JSON.stringify(data));
                        setIsEmailVerified(true)
                        navigate("/main/dashboard")
                    }
                } catch (error) {
                    console.error("Error verifying email:", error);
                }
            }
        };

        verifyEmail();
    }, [token, setEmployee, navigate]);

    return (
        <>
            {
                isEmailVerified &&
                <div style={{ fontSize: "2rem", color: "white", display: "grid", placeItems: "center", height: "20vh" }}>
                    Successfully, You are verified
                </div>
            }
        </>
    );
}

export default VerifyClientContact;
